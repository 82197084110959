import React, { useState, useEffect, useRef } from "react";
import { motion, transform } from 'framer-motion'
import { NavLink } from 'react-router-dom';

import { useSpring, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'

function Card({setCard, card}) {

  // const [props, set] = useSpring(() => ({ x: 0, y: 0,     config: { mass: 0.2, tension: 400, bounce: 0},
  // }))
  // const bind = useDrag(({ down, movement: [x, y] }) => {
  //   set({ x: down ? x : 0, y: down ? y : 0 })
  // })


  return (
    <>
        <motion.div onClick={() => setCard(s => !s)} className="card-wrapper" transition={{duration: 0.1}} initial={{ opacity: 0}} animate={{ opacity: 1}} exit={{ opacity: 0}} >

        {/* <motion.div className="hero" transition={{duration: 0.2}} initial={{ opacity: 0, scale: 0.9}} animate={{ opacity: 1, scale: 1}} exit={{ opacity: 0, scale: 0.9}} > */}
  <>



  {/* <animated.div {...bind()} style={props} > */}
<div className="cardcontainer">
  <div>
    Heidlmair<br></br>
    Kommunikation
    <img className="Logo"></img>
  </div>
  <div>
  <div className="width50">
    <p>
Heidlmair Linz<br></br>
Spittelwiese 6<br></br>
4020 Linz, Austria<br></br>
<a href="tel:+43 732 772550">+43 732 772550</a><br></br>
<a href="mailto:linz@heidlmair.com">linz@heidlmair.com</a>
    </p>
  </div>
  <div className="width50">
    <p>
Heidlmair Wien<br></br>
Marktgasse 56<br></br>
1090 Wien, Austria<br></br>
<a href="tel:+43 1 8902502">+43 1 8902502</a><br></br>
<a href="mailto:wien@heidlmair.com">wien@heidlmair.com</a>
    </p>
  </div>

</div>
</div>
{/* </animated.div> */}

       </>
</motion.div>

{/* </motion.div> */}
</>
  )
}

export default Card