import './App.css';
import React, { useState, useEffect, Suspense } from 'react';
import MenuJS from './Components/MenuJS'
import Holding from './Components/Holding';
import { AnimatePresence } from 'framer-motion';
import CookieConsent from "react-cookie-consent";

const Main = React.lazy(() => import('./Main'));

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}


function App() {

  
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  }, [])

  const [ dark, setDark ] = useState(false)

  const [categories, setCategories] = useState([]);
  const [lastScrollY, setLastScrollY] = useState(0);


  
  useEffect(() => {


    // Debounced scroll handler
    const onScroll = debounce(() => {
      setLastScrollY(window.scrollY);
      // Other scroll-related logic...
    }, 100); // Set the delay (in milliseconds) for the debounce function

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
    <MenuJS />
    {/* <CookieConsent
        location="top"
        buttonText="I agree"
        cookieName="myAppCookieConsent"
        style={{ background: "#000000" }}
        buttonStyle={{ background: "#000000", color: "#ffffff", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent> */}
  

 <Suspense fallback={
  <div className="preload-wrapper">
      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
   }>
<Main />

        <div>

   </div>
      </Suspense>

      </>
  );
}

export default App;