import React from 'react'
import Card from './Card'
import { motion, transform } from 'framer-motion'
import { NavLink } from 'react-router-dom'

function Holding({card, setCard, searchInput, filter, toggle, handleChange, expand, active, open}) {
  
  const pageTransitions = {
    initial: {
      opacity: 1
  },
  in: {
    opacity: 1
  },
  out: {
      opacity: 0
  }
    };
  
  return (
    <>
        <motion.div  className="intro-wrapper" transition={{duration:
   0.3}} initial="initial" animate="in" exit="out" variants={pageTransitions} >

        <motion.div className="hero"  >
  <>




<div className="cardcontainer">
  <div>
    Heidlmair<br></br>
    Kommunikation
    <img className="Logo"></img>
  </div>
  <div>
  <div className="width50">
    <p>
Heidlmair Linz<br></br>
Spittelwiese 6<br></br>
4020 Linz, Austria<br></br>
<a href="tel:+43 732 772550">+43 732 772550</a><br></br>
<a href="mailto:linz@heidlmair.com">linz@heidlmair.com</a>
    </p>
  </div>
  <div className="width50">
    <p>
Heidlmair Wien<br></br>
Marktgasse 56<br></br>
1090 Wien, Austria<br></br>
<a href="tel:+43 1 8902502">+43 1 8902502</a><br></br>
<a href="mailto:wien@heidlmair.com">wien@heidlmair.com</a>
    </p>
  </div>

</div>
</div>


       </>
</motion.div>

</motion.div>
</>
  )
}

export default Holding