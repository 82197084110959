

import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import useWindowDimensions from './Measure';
import { motion, transform } from 'framer-motion'
import { NavLink } from 'react-router-dom';

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};

function usePip(ref) {
    const { height, width } = useWindowDimensions();

    const [boxSize, setBoxSize] = useState({ width: 360, height: 56 });
  
    useEffect(() => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setBoxSize({ width: rect.width, height: rect.height });
      }
    }, []);
  
    const [styles, set] = useSpring(() => ({
      x: width / 2 - boxSize.width / 2,
      y: height - boxSize.height - 5,
    }));

    useEffect(() => {
        const handleResize = () => {
          const rect = ref.current.getBoundingClientRect();
          setBoxSize({ width: rect.width, height: rect.height });
      
          const defaultX = rect.x + rect.width / 2 > window.innerWidth / 4
            ? window.innerWidth / 2 - rect.width / 2
            : rect.x + rect.width / 2 > window.innerWidth / 1.5
            ? window.innerWidth - 28 - rect.width
            : styles.x.get();
      
          const defaultY = rect.y + rect.height / 2 > window.innerHeight / 4
            ? window.innerHeight - 5 - rect.height
            : rect.y + rect.height / 2 > window.innerHeight / 1.5
            ? window.innerHeight - 28 - rect.height
            : styles.y.get();
      
          set({ x: defaultX, y: defaultY });
        };
      
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    const bind = useDrag((_a) => {

        var { event, down, last, movement: [x, y], metaKey } = _a, rest = __rest(_a, ["down", "last", "movement", "metaKey"]);
        
        let defaultX = 28;
        let defaultY = 28;
        if (last && ref.current) {
            const rect = ref.current.getBoundingClientRect();

            if (rect.x + rect.width / 2 > window.innerWidth / 4) {
                defaultX = window.innerWidth / 2 - rect.width / 2;
            }
            if (rect.x + rect.width / 2 > window.innerWidth / 1.5) {
                defaultX = window.innerWidth - 28 - rect.width;
            }
            if (rect.y + rect.height / 2 > window.innerHeight / 4) {
                defaultY = window.innerHeight / 2 - rect.height / 2;
            }
            if (rect.y + rect.height / 2 > window.innerHeight / 1.5) {
                defaultY = window.innerHeight - 5 - rect.height;
            }
        }
        set({
            x: down || metaKey ? x : defaultX,
            y: down || metaKey ? y : defaultY,
            immediate: down
        });
    }, 
    { 
        initial: () => [styles.x.get(), styles.y.get()] ,
    },
 
        );

        
      
    return [bind, Object.assign(Object.assign({}, styles), { position: "fixed", left: 0, top: 0, zIndex: 999999 })];
}
function Simple() {
   
    const indicator = document.querySelector('.nav-indicator');
    const items = document.querySelectorAll('.nav-item');
  
    function handleIndicator(el) {
      items.forEach(item => {
        item.classList.remove('is-active');
        item.removeAttribute('style');
      });
  
      indicator.style.width = `${el.offsetWidth}px`;
      indicator.style.left = `${el.offsetLeft}px`;
      indicator.style.color = el.getAttribute('active-color');
  
      el.classList.add('is-active');
      el.style.color = el.getAttribute('active-color');
    }
  
  
    items.forEach((item, index) => {
      item.addEventListener('click', (e) => { handleIndicator(e.target) });
      item.classList.contains('is-active') && handleIndicator(item);
    });
   
    const ref = React.useRef(null);
    const [props, styles] = usePip(ref);


    return (
        <>

        <animated.div ref={ref} {...props()} style={{ ...styles }}>
        <div className="top-bar">

<nav className="nav">
  <NavLink to="/think" >
    <span className="nav-item" active-color="white">
      Think
    </span>
  </NavLink>
  <NavLink exact to="/" >
    <span className="nav-item is-active" active-color="white">
      See
    </span>
  </NavLink>
  <NavLink to="/feel" >
    <span className="nav-item" active-color="white">
      Feel
    </span>
  </NavLink>


  <span className="nav-indicator">
    {/* <span className="indicator-dot"></span> */}
  </span>
</nav>

</div>        </animated.div>
      </>
    );
}
export default Simple;